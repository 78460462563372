<template>
    <v-container>
        <v-card class="mb-10">
            <v-card-title class="card-title">Arena Form</v-card-title>
        </v-card>
        <v-form :readonly="readOnly">
            <v-row>
                <v-col cols="6">
                    <v-switch
                        v-model="arena.active"
                        hide-details
                        inset
                        :label="arena.active ? 'Active' : 'Not Active'"
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="arena.coordinates"
                        label="Coordinates"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="arena.name"
                        label="Name"
                        :clearable="clearable"
                    />
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="arena.address"
                        label="Address"
                        :clearable="clearable"
                    />
                </v-col>
            </v-row>
            <v-row align="center">
                <v-col cols="6">
                    <v-file-input
                        v-model="image"
                        class="mt-3"
                        accept="image/*"
                        show-size
                        label="Arena Image"
                        :clearable="clearable"
                        @change="changeImage"
                        @click:clear="clearImage()"
                    />
                </v-col>
                <v-col cols="6">
                    <v-img
                        v-if="image"
                        width="200px"
                        height="auto"
                        contain
                        :src="imagePath"
                    />
                </v-col>
            </v-row>
        </v-form>
        <div v-if="mode !== 'show'" class="submit-button-wrapper">
            <v-btn :loading="loading" class="mx-2 white--text" :disabled="shouldDisableSubmit()" large color="black" @click="onSubmit">
                Submit
            </v-btn>
            <v-btn class="mx-2 white--text" large color="red" @click="$router.go(-1)">
                Cancel
            </v-btn>
        </div>
        <alert :show-alert="showAlert" :message="message" :type="type" />
    </v-container>
</template>

<script>
    import arenaService from '@/services/arena'
    import Alert from '@/components/Alert'

    export default {
        name: 'ArenaForm',
        components: { Alert },
        data: () => {
            return {
                showAlert: false,
                message: null,
                type: null,
                mode: null,
                googleApiHost: 'https://storage.googleapis.com',
                readOnly: false,
                clearable: true,
                loading: false,
                startDate: null,
                endDate: null,
                arena: {
                },
                arenaId: null,
                rules: [
                    v => !!v || 'Field is required'
                ],
                categories: [],
                image: {
                    name: 'Image file'
                },
                imageChanged: false,
                imagePath: ''
            }
        },
        async created() {
            this.mode = this.$route.params.mode
            if (this.mode === 'show') {
                this.readOnly = true
                this.clearable = false
            }
            this.arenaId = this.$route.params.arenaId
            if (this.arenaId) {
                arenaService.getArena(this.arenaId).then(resp => {
                    this.arena = resp.data
                    if (this.arena.image) {
                        this.imagePath = this.googleApiHost + '/' + this.arena.image
                    }
                })
            }
        },
        methods: {
            changeImage() {
                this.imageChanged = true
                if (this.image) {
                    this.imagePath = URL.createObjectURL(this.image)
                }
            },
            clearImage() {
                this.imagePath = ''
                this.arena.image = null
            },
            setAlert(message, type) {
                this.showAlert = true
                this.message = message
                this.type = type
                setTimeout(() => { this.showAlert = false }, 7000)
            },
            shouldDisableSubmit() {
                return false
            },
            setStartDate(date) {
                this.startDate = date
            },
            setEndDate(date) {
                this.endDate = date
            },
            onEdit() {
                this.$router.push(`/arenas/${this.arenaId}/edit`)
            },
            createArena() {
                arenaService.createArena(this.arena).then(arenaResponse => {
                    if (arenaResponse.id) {
                        this.arenaId = arenaResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/arenas`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            editArena() {
                arenaService.updateArena(this.arenaId, this.arena).then(arenaResponse => {
                    if (arenaResponse.id) {
                        this.arenaId = arenaResponse.id
                    }
                    this.loading = false
                    this.$router.push(`/arenas`)
                }).catch((error) => {
                    this.loading = false
                    this.setAlert(error.response.data.message, 'error')
                })
            },
            uploadImage() {
                if (!this.imageChanged || this.image == null) {
                    return
                }
                let formData = new FormData()
                formData.append('file', this.image)
                return arenaService.uploadFile(formData)
            },
            async onSubmit() {
                this.loading = true
                const uploadImageResponse = await this.uploadImage()
                const imageUrl = uploadImageResponse?.data.content || null
                if (imageUrl) {
                    this.arena.image = imageUrl
                }
                if (this.mode === 'edit') {
                    this.editArena()
                } else if (this.mode === 'create') {
                    this.createArena()
                }
            }
        }

    }
</script>
<style>
.tox .tox-editor-header {
    z-index: 0 !important;
}
.tox .tox-statusbar {
    display: none !important;
}

.tox .tox-toolbar--scrolling {
    flex-wrap: wrap !important;
}
.tox.tox-tinymce.tox-platform-touch {
    height: 40vh !important;
    min-height: 350px;
}
</style>
<style scoped>
.body-label {
    color: rgba(0, 0, 0, 0.6);
}
.body-editor {
    margin-bottom: 2vh;
}
.edit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.submit-button-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 2vh;
}

.img-preview {
    max-width: 70%;
    margin: 0 auto;
}

.image-input {
    padding: 20px;
    width: 100%;
    border-style: groove;
    border: #D3D3D3 solid 2px;
    border-radius: 15px 50px;
    margin-top: 1vh;
}

.green-border {
    border-color: green;
}

.delete-btn {
    display: flex;
    justify-content: flex-end;
}
</style>
